<template>
    <div class="home_section1_container dp_flex flex_justify_center" @click="videoPlay">
        <div class="home_section_dim"></div>
        <video
            id="aplayzvideo"
            class="introuduce_video"
            loop
            muted
            playsinline
            preload="auto"
            src="/media/images/video/az_official.mp4"
        >
            브라우저에서는 비디오를 지원하지 않습니다.
        </video>
        <div class="home_section1_paragraph">
            <h1 class="home_title1 animate__animated animate__fadeInDown">
                <slot name="hometitle1">
                    <span class="home_title1_text">세상 모든 공간을</span>
                    <div class="home_title1_br"></div>
                    <figure class="home_title_logo_wrap">
                        <img
                            class="dp_inlineblock home_title_logo"
                            src="/media/images/ev_main_con01_logo.png"
                            alt="ev_main_con01_logo"
                        />
                    </figure>

                    <span class="home_title1_text">하라!</span>
                </slot>
            </h1>
            <ul
                class="appdowonload_btngroup flex_justify_center align_center animate__animated animate__fadeInDown animate__delay-1s"
            >
                <li>
                    <button class="appdowonload_btn" @click="clickAppStore('GoogleStore')">
                        <img class="store_btn_img" src="/media/images/googlestore_icon.png" alt="구글 스토어 아이콘" />
                        <span class="store_btn_name">Google Play</span>
                    </button>
                </li>
                <li>
                    <button class="appdowonload_btn" @click="clickAppStore('AppleStore')">
                        <img class="store_btn_img" src="/media/images/appstore_icon.png" alt="애플 스토어 아이콘" />
                        <span class="store_btn_name">App Store</span>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { WOW } from 'wowjs';
export default {
  name: 'HomeSection1',

  data () {
    return {
      wow: null
    };
  },

  mounted () {
    this.wow = new WOW({ live: false });
    this.wow.init();
    this.wow.sync();

    this.videoPlay();
  },

  methods: {
    videoPlay () {
      const video = document.querySelector('#aplayzvideo');
      video.play();
    },
    clickAppStore (storeGb = 'GoogleStore') {
      // 모바일일 경우
      if (/Mobi/i.test(window.navigator.userAgent)) {
        window.open(`https://aplayz.page.link/app`, 'blank');
      } else if (storeGb === 'GoogleStore') {
        // 안드로이드
        window.open('https://play.google.com/store/apps/details?id=com.mobileaos.aplayz', 'blank');
      } else if (storeGb === 'AppleStore') {
        // 아이폰
        window.open('https://apps.apple.com/kr/app/aplayz/id6446978790', 'blank');
      }
    }
  }
};
</script>
<style scoped src="@/assets/css/home/homesection1.css"></style>
